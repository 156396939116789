import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import Link from '../link'
import BlogCard from './blog-card'

import { slugify } from '../../utils'

const ModuleBlogTeaser = ({ blogPosts }) => {

  const intl = useIntl()

  return (
    <>
      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-light">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor" />
          </svg>
        </div>
      </div>

      <section className="py-7 py-md-10 bg-light mb-0">
        <div className="container">
          <div className="row align-items-center mb-7">
            <div className="col-12 col-md">
              <h3 className="mb-0 h2">
                <FormattedMessage id="blogOverviewHeadline" />
              </h3>
              <p className="mb-0 text-muted">
                <FormattedMessage id="blogTeaserSubline" />
              </p>
            </div>
            <div className="col-12 col-md-auto">
              <Link to={`${slugify(intl.locale)}/blog`} className="btn btn-sm btn-outline-gray-300 d-none d-md-inline">
                <FormattedMessage id="blogViewAll" />
              </Link>
            </div>
          </div>
          <div className="row">
            {blogPosts.map((blogPost, i) => (
              <BlogCard
                data={blogPost}
                isHidden={i > 2}
                key={blogPost.id}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default ModuleBlogTeaser