import React, { useRef, useEffect } from 'react'
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css'
import { useWindowSize } from '../../utils/useWindowSize';
import { FormattedMessage } from 'react-intl';

const SectionMap = ({ node_locale }) => {

  const mapRef = useRef(null)

  const { width } = useWindowSize()

  useEffect(() => {
    mapboxgl.accessToken = process.env.GATSBY_MAPBOX_ACCESS_TOKEN;
    const map = new mapboxgl.Map({
      container: mapRef.current,
      style: 'mapbox://styles/dept-bade/ck8g0jtdo1xzx1ipss6r6rtj3',
      center: [13.3984, 52.5],
      zoom: 12,
      scrollZoom: false,
      locale: node_locale === "en" ? "en_US" : "de_DE"
    })

    // custom marker
    const marker = document.createElement('div')
    marker.innerHTML = `<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 218 218" width="96" height="96">
        <g>
          <path d="M66.426 143.426c-23.431-23.431-23.431-61.42 0-84.852 23.432-23.432 61.422-23.432 84.853 0 23.432 23.431 23.432 61.421 0 84.852l-42.426 42.427-42.427-42.427z" fill="#335EEA"/>
          <path d="M68.535 94.174H86.29v3.318H72.147v4.244h10.505v3.317H72.147v4.244h14.116v3.318H68.535v-18.44zM93.89 107.085c0 1.543 2.02 2.572 5.076 2.572 3.308 0 5.076-.952 5.076-2.701 0-1.466-1.591-2.108-5.227-2.108-5.733 0-8.183-1.544-8.183-5.273 0-3.755 2.905-5.787 8.334-5.787 5 0 8.334 2.367 8.334 5.89h-3.611c0-1.543-1.894-2.572-4.698-2.572-3.08 0-4.697.849-4.697 2.47 0 1.414 1.44 1.954 4.9 1.954 5.985 0 8.51 1.672 8.51 5.426 0 3.91-3.03 6.019-8.687 6.019-5.278.025-8.763-2.341-8.763-5.89h3.636zM110.179 94.174h18.107v3.318h-7.248v15.123h-3.611V97.492h-7.248v-3.318zM135.293 99.609c0-1.59.932-2.847 2.515-3.677-1.257-.806-2.003-2.016-2.003-3.463 0-3.392 2.33-5.266 6.522-5.266 4.216 0 6.522 1.874 6.522 5.266 0 1.47-.745 2.657-2.003 3.463 1.561.807 2.516 2.064 2.516 3.677 0 3.392-2.516 5.266-7.011 5.266-4.589.024-7.058-1.85-7.058-5.266zm3.517 0c0 1.328 1.211 2.04 3.517 2.04 2.283 0 3.517-.712 3.517-2.04s-1.211-2.04-3.517-2.04c-2.282 0-3.517.735-3.517 2.04zm.489-7.14c0 1.328 1.025 2.04 3.005 2.04s3.005-.712 3.005-2.04c0-1.329-1.025-2.04-3.005-2.04-1.957 0-3.005.711-3.005 2.04z" fill="#fff"/>
        </g>
      </svg>`

    new mapboxgl.Marker({
      element: marker,
      anchor: 'bottom'
    }).setLngLat([13.3984, 52.4939]).addTo(map)
  }, [node_locale])

  return (
    <section className="pt-8 pt-md-0 border-bottom bg-light">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            {width <= 768
              ? (<div className="embed-responsive embed-responsive-1by1">
                <div ref={mapRef} className="embed-responsive-item" />
              </div>)
              : (<div className="position-relative h-100 vw-50 float-right">
                <div ref={mapRef} className="h-100 w-100" />
                <div className="shape shape-right shape-fluid-y svg-shim text-light">
                  <svg viewBox="0 0 100 1544" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 386V0H100V1544H50V1158L0 386Z" fill="currentColor" />
                  </svg>
                </div>
              </div>)
            }
          </div>
          <div className="col-12 col-md-6 col-lg-5 offset-lg-1 py-8 py-md-11 py-lg-12">

            <h2 className="line-height-compact">
              <FormattedMessage id="mapTitle1" />
              <span className="text-success"><FormattedMessage id="mapTitle2" /></span>
            </h2>

            <p className="text-gray-700 my-6">
              <FormattedMessage id="mapText" />
            </p>

          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionMap