import React from 'react'
import Img from 'gatsby-image'

const HeroTeaserScreenshot = ({ children, image, imgIsRight }) => (
  <section className="position-relative py-8 py-md-11">
    <div className="container">
      <div className="row align-items-center">
        <div className={`col-12 col-md-6 ${imgIsRight && "order-md-2"}`}>
          <div className={`expand-sm ${imgIsRight ? "expand-md-right" : "expand-md-left"}`}>
            {image && (
              <Img
                fluid={image.screenshot}
                alt={image.title}
                className="img-fluid"
                style={{ width: "100%", height: "100%" }}
                imgStyle={{ objectFit: "contain" }}
              />)}
          </div>
        </div>
        <div className="col-12 col-md-6 order-md-1">
          {children}
        </div>
      </div>
    </div>
  </section>
)

export default HeroTeaserScreenshot