import React from 'react'
import Img from 'gatsby-image'

import WaveBottom from '../shapes/wave-bottom'

const HeroTeaserImageBG = ({ children, image }) => {
  return (
    <section className="position-relative heroteaser">
      <div className="bg-gray-300 position-absolute top-0 left-0 right-0 bottom-0 pb-px overflow-hidden">
        {image && (
          <Img
            fluid={image.background}
            alt={image.title}
            className="img-fluid"
            style={{ width: "100%", height: "100%" }}
            fadeIn={false}
          />
        )}
      </div>
      <div className="shape shape-bottom shape-fluid-x shape-no-scale svg-shim">
        <WaveBottom className="green-100" />
      </div>
      <div className="container h-100">
        <div className="row h-100 flex justify-content-center align-items-center">
          <div className="col-12 col-lg-8 text-white text-shadow-lg pb-4">
            {children}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroTeaserImageBG