import React from 'react'

import RichText from '../rich-text'
import { BLOCKS } from '@contentful/rich-text-types'

import HeroTeaserImageBG from '../modules/hero-teaser-image-bg'
import HeroTeaserIllustration from '../modules/hero-teaser-illustration'
import HeroTeaserScreenshot from '../modules/hero-teaser-screenshot'
import HeroTeaserText from '../modules/hero-teaser-text'

const SectionHeroteaser = ({ image, imageLayout, richText, node_locale, displayWave }) => {
  const imgIsRight = imageLayout.indexOf("-right") > 0

  let Wrapper
  let wrapperProps = {}

  switch (imageLayout) {
    case "image-bg":
      Wrapper = HeroTeaserImageBG
      break
    case "screenshot-left":
    case "screenshot-right":
      Wrapper = HeroTeaserScreenshot
      break
    case "illustration-left":
    case "illustration-right":
      Wrapper = HeroTeaserIllustration
      wrapperProps = { displayWave }
      break
    case "no-image":
    default:
      Wrapper = HeroTeaserText
      break
  }

  let options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (_node, children) => (
        <h1 className="display-3 mb-6">
          {children}
        </h1>
      ),
    }
  }

  return (
    <Wrapper image={image} imgIsRight={imgIsRight} {...wrapperProps}>
      <RichText content={richText} options={options} lang={node_locale} />
    </Wrapper>
  )
}


export default SectionHeroteaser