import React from 'react'
import Img from 'gatsby-image'
import RichTextModule from './rich-text'
import Icon from '../icon'
import Link from '../link'

const ModulePersonCard = ({ profilePicture, profileText, name, jobTitle, email, linkedin, lang }) => {
  return (
    <div className={`card mb-6 shadow-light-lg lift lift-lg`}>

      {profilePicture
        ? (
          <div className="card-img-top">
            <Img
              fluid={profilePicture.fluid}
              alt={name}
              className="card-img-top"
            />
            <div className="position-relative">
              <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                <svg viewBox="0 0 2880 480" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M2160 0C1440 240 720 240 720 240H0V480H2880V0H2160Z" fill="currentColor" />
                </svg>
              </div>
            </div>
          </div>
        )
        : (
          <div className="card-img-top pt-1 bg-gradient" />
        )
      }
      <div className="card-body">
        <h3 className="line-height-compact mb-2 font-size-h3">
          {name}
        </h3>
        <h6 className="text-uppercase text-muted mb-2 line-height-compact">
          {jobTitle}
        </h6>
        <h6 className="text-muted mb-5 line-height-compact">
          <Link to={linkedin} className="text-reset">
            <Icon id="linkedin" width={22} height={24} className="pb-1 mr-1" />
          </Link>
          <a href={`mailto:${email}`} className="text-reset">
            <Icon id="mail" width={28} height={28} className="pb-1 mx-1" />
          </a>
        </h6>
        <RichTextModule content={profileText} lang={lang} />
      </div>

    </div>
  )
}

export default ModulePersonCard