import React from 'react'
import { graphql } from 'gatsby'

import LandingpageLayout from '../components/layouts/landingpage'

const LandingpageTemplate = ({ data: { page } }) => {
  const data = {
    content: page.content,
    node_locale: page.node_locale,
    meta: {
      title: page.title,
      description: page.description,
      imageUrl: page.metaImage ? page.metaImage.fixed.src : null,
      slug: page.slug,
      locale: page.node_locale
    }
  }
  return <LandingpageLayout {...data} />
}

export const query = graphql`
  query PageQuery($id: String!) {
    page: contentfulPagePage(id: {eq: $id}) {
      id
      slug
      title
      node_locale
			description
      metaImage {
        fixed(width: 1200, height: 630, quality: 90, resizingBehavior:FILL) {
          src
        }
      }
      content {
        __typename
        ... on ContentfulSectionHeroteaser {
          id
          imageLayout
          image {
            default: fluid(maxWidth: 600, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
            screenshot: fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid_withWebp
            }
            background: fluid(maxWidth: 2400, maxHeight: 960, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
            title
          }
          richText {
            json
          }
          node_locale
        }
        ... on ContentfulSectionColumns {
          id
          node_locale
          background
          padding
          gridSetting
          content {
            __typename
            ... on ContentfulRichText {
              id
              content {
                json
              }
              isCardLayout
              isCentered
            }
            ... on ContentfulPerson {
              id
              name
              jobTitle
              profileText {
                json
              }
              profilePicture {
                fluid(maxWidth: 600, maxHeight: 400, cropFocus:FACE, resizingBehavior:FILL) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              email
              linkedin
            }
          }
        }
        ... on ContentfulSectionKpiZeile {
          id
          kpi {
            id
            kpiDescription
            kpiValue
            animation
          }
        }
        ... on ContentfulSectionStaticModule {
          id
          module
          node_locale
        }
      }
    }
  }
`

export default LandingpageTemplate