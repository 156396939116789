import React from 'react'
import Img from 'gatsby-image'

import Wave1 from '../shapes/wave-1'
// import { motion, useTransform, useViewportScroll } from 'framer-motion'
// import { useWindowSize } from '../../utils/useWindowSize'

const HeroTeaserIllustration = ({ children, image, imgIsRight, displayWave }) => {

  // const { scrollY } = useViewportScroll()
  // const { width } = useWindowSize()
  // const waveStyleDesktop = {
  //   y: useTransform(scrollY, [0, 400], [5, -160], { ease: t => t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t })
  // }
  // const waveStyleMobile = {}

  return (
    <section className="position-relative py-0 py-md-6">
      {displayWave && (
        <div className="position-absolute top-0 left-0 right-0 pt-15 pt-md-14 bg-white">
          <Wave1 style={{ color: "hsl(153, 80%, 96%)" }} />
        </div>
      )}
      <div className="container">
        <div className="row align-items-center">
          <div className={`col-12 col-md-5 col-lg-6 ${imgIsRight && "order-md-2"}`}>
            <div className={`expand-sm ${imgIsRight ? "expand-md-right" : "expand-md-left"}`}>
              {image && <Img fluid={image.default} alt={image.title} className="img-fluid" />}
            </div>
          </div>

          <div className="col-12 col-md-7 col-lg-6 order-md-1 pb-7 mt-n3">
            {children}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroTeaserIllustration