import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import BlogTeaser from '../modules/blog-teaser'

const SectionBlogCards = ({ node_locale }) => {

  const data = useStaticQuery(query)

	/* 
		[INFO]
		only use the query that matches the current language – 
		both languages need to be queried, because graphql variables 
		are not supported in this static query.
	*/
  const latestPosts = data[`latestPosts_${node_locale}`].nodes

  return (
    <BlogTeaser blogPosts={latestPosts} />
  )
}

const query = graphql`
  query {
		latestPosts_de: allContentfulPageBlogPost(limit: 4, sort: {fields: createdAt, order: DESC}, filter: {node_locale: {eq: "de"}}) {
			nodes {
        ...BlogCard
      }
		}
		latestPosts_en: allContentfulPageBlogPost(limit: 4, sort: {fields: createdAt, order: DESC}, filter: {node_locale: {eq: "en"}}) {
			nodes {
        ...BlogCard
      }
		}
  }
`

export default SectionBlogCards