import React from 'react'

import SEO from '../modules/seo/seo'
import * as Section from '../sections'

const LandingpageLayout = ({ content, node_locale, meta }) => {
  return (
    <div className="bg-gradient-green-100-white page-margin">
      <SEO {...meta} />

      {content.map((section, i) => {
        let Cmp

        switch (section.__typename) {
          case "ContentfulSectionHeroteaser":
            Cmp = Section.Heroteaser
            section.displayWave = i === 0
            break;
          case "ContentfulSectionColumns":
            Cmp = Section.Columns
            break;
          case "ContentfulSectionKpiZeile":
            Cmp = Section.KpiRow
            break;
          case "ContentfulSectionStaticModule":
            switch (section.module) {
              case "[Teaser] Latest Blog posts":
                Cmp = Section.BlogCards
                break;
              case "[Teaser] Map":
                Cmp = Section.OfficeMap
                break;
              case "Abstand klein (white)":
                Cmp = props => <div {...props} className="w-100 py-2 py-md-5" />
                break;
              case "Abstand groß (white)":
                Cmp = props => <div {...props} className="w-100 py-4 py-md-7" />
                break;
              case "Abstand klein (light-gray)":
                Cmp = props => <div {...props} className="w-100 py-2 py-md-5 bg-gray-200" />
                break;
              case "Abstand groß (light-gray)":
                Cmp = props => <div {...props} className="w-100 py-4 py-md-7 bg-gray-200" />
                break;
              default:
                return <p className="badge badge-danger">Static Module '{section.module}' not handled</p>
            }
            break;
          default:
            return <p className="badge badge-danger">Section '{section.__typename}' not found</p>
        }

        return <Cmp key={`${section.id}-${node_locale}-${i}`} {...section} />
      })}
    </div>
  )
}
export default LandingpageLayout