import React from 'react'

import RichTextModule from '../modules/rich-text'
import PersonCard from '../modules/person-card'
import pattern from '../../img/patterns/pattern-1.svg'

const SectionColumns = props => {
  const { content, background, padding, node_locale, gridSetting = "▓▓▓▓▓▓▓▓▓▓▓▓" } = props

  const getLayoutForColumn = i => {
    let layout

    switch (gridSetting) {
      case "░░░▓▓▓▓▓▓░░░":
        layout = "col-12 col-sm-10 col-md-8 col-lg-6"
        break;
      case "░░▓▓▓▓▓▓▓▓░░":
        layout = "col-12 col-md-10 col-lg-8"
        break;
      case "░░▓▓▓░░▓▓▓░░":
        layout = "col-12 col-md-6 col-lg-5"
        break;
      case "░▓▓▓▓▓▓░▓▓▓░":
        layout = ["col-12 col-md-10 col-lg-8", "col-12 col-md-10 col-lg-4"]
        break;
      case "░▓▓▓░▓▓▓▓▓▓░":
        layout = ["col-12 col-md-10 col-lg-4", "col-12 col-md-10 col-lg-8"]
        break;
      case "▓▓▓▓▓▓▓▓▓▓▓▓":
      default:
        layout = content.length === 4 ? `col-12 col-md-6 col-lg-3` : content.length === 3 ? `col-12 col-lg-4` : content.length === 2 ? `col-12 col-sm-6` : `col-12 col-lg-${12 / content.length}`
        break;
    }
    return `${Array.isArray(layout) ? layout[i] : layout} d-flex align-content-stretch my-3 mt-sm-4 mb-sm-5`
  }

  const patternStyle = background === "dark" ? { backgroundImage: `url(${pattern})` } : {}
  const sectionClasses = `${padding === "large" ? "py-8 py-md-12 " : "my-0 "}${background === "dark" ? "bg-dark " : ""}${background === "light-gray" ? "bg-gray-200" : ""}`

  return (
    <section className={sectionClasses} style={patternStyle}>
      <div className="container">
        <div className="row justify-content-center">
          {content.map((col, i) => {
            switch (col.__typename) {
              case "ContentfulRichText":
                return (
                  <div className={getLayoutForColumn(i)} key={`${col.id}-${i}`}>
                    <RichTextModule {...col} backgroundDark={background === "dark"} lang={node_locale} />
                  </div>)
              case "ContentfulPerson":
                return (
                  <div className={getLayoutForColumn(i)} key={`${col.id}-${i}`}>
                    <PersonCard {...col} lang={node_locale} />
                  </div>)
              default:
                return (
                  <div className={getLayoutForColumn(i)} key={`${col.id}-${i}`}>
                    <span className="badge badge-danger">Column content '{col.__typename}' not found</span>
                  </div>)
            }
          })}
        </div>
      </div>
    </section>
  )
}

export default SectionColumns