import React from 'react'
//import { useViewportScroll, useTransform, motion } from 'framer-motion'

const KPIRow = ({ kpi }) => {

	/* 
			kpi
			- kpiValue
			- kpiDescription
			- animation ("none", "count-up", "count-down")
	*/

  return (
    <section className="py-8 py-md-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card-group card-border card-border-lg border-primary shadow-light-lg">
              {kpi.map(kpiEntry => (
                <KPIEntry key={kpiEntry.id} {...kpiEntry} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const KPIEntry = ({ kpiValue, kpiDescription }) => {
  return (
    <div className="card">
      <div className="card-body">

        {/*--- Heading ---*/}
        <h2 className="font-weight-bold text-center mb-0">
          {kpiValue}
        </h2>

        {/*--- Text ---*/}
        <p className="text-center text-muted mb-0">
          {kpiDescription}
        </p>

      </div>
    </div>
  )
}

/*

import { tween, easing } from "popmotion";

tween({
  from: 100,
  to: 0,
  duration: 3000,
  ease: easing.easeOut
}).start({
  update(value) {
    node.textContent = value.toFixed(0);
  }
});

*/

export default KPIRow